import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import languageReducer from "./Language/language.reducer";

import modalReducer from "./ValentineModal/modal.reducer";
import easterReducer from "./EasterModal/modal.reducer";

import affReducer from "./Affiliate/affiliate.reducer";
import userReducer from "./User/user.reducer";
import orderReducer from "./Order/order.reducer";
import ticketReducer from "./Ticket/ticket.reducer";
import passangerReducer from "./Passangers/passanger.reducer";
import timetableReducer from "./Timetable/timetable.reducer";
import couponReducer from "./Coupon/coupon.reducer";

export const rootReducer = combineReducers({
  languageData: languageReducer,
  isModal: modalReducer,
  isEasterModal: easterReducer,
  user: userReducer,
  affiliate: affReducer,
  order: orderReducer,
  ticket: ticketReducer,
  passanger: passangerReducer,
  valentinesModal: modalReducer,
  timetable: timetableReducer,
  couponReducer,
});

const configStorage = {
  key: "root",
  storage,
};

export default persistReducer(configStorage, rootReducer);
