const INITIAL_STATE = {
  couponCode: "",
  appliable: "weekdays",
  valid: null,
};

const couponReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_UTM_ORIGIN":
      return {
        ...state,
        couponCode: action.payload.code,
        appliable: action.payload.appliable,
        valid: action.payload.valid,
      };
    default:
      return state;
  }
};

export default couponReducer;
